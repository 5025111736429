// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-aceites-js": () => import("./../../../src/pages/products/aceites.js" /* webpackChunkName: "component---src-pages-products-aceites-js" */),
  "component---src-pages-products-aguas-jugos-js": () => import("./../../../src/pages/products/aguas-jugos.js" /* webpackChunkName: "component---src-pages-products-aguas-jugos-js" */),
  "component---src-pages-products-area-premium-js": () => import("./../../../src/pages/products/area-premium.js" /* webpackChunkName: "component---src-pages-products-area-premium-js" */),
  "component---src-pages-products-barricas-js": () => import("./../../../src/pages/products/barricas.js" /* webpackChunkName: "component---src-pages-products-barricas-js" */),
  "component---src-pages-products-botellas-vino-borgona-eco-js": () => import("./../../../src/pages/products/botellas_vino/borgona-eco.js" /* webpackChunkName: "component---src-pages-products-botellas-vino-borgona-eco-js" */),
  "component---src-pages-products-botellas-vino-borgona-js": () => import("./../../../src/pages/products/botellas_vino/borgona.js" /* webpackChunkName: "component---src-pages-products-botellas-vino-borgona-js" */),
  "component---src-pages-products-botellas-vino-burdeos-eco-js": () => import("./../../../src/pages/products/botellas_vino/burdeos-eco.js" /* webpackChunkName: "component---src-pages-products-botellas-vino-burdeos-eco-js" */),
  "component---src-pages-products-botellas-vino-burdeos-js": () => import("./../../../src/pages/products/botellas_vino/burdeos.js" /* webpackChunkName: "component---src-pages-products-botellas-vino-burdeos-js" */),
  "component---src-pages-products-botellas-vino-js": () => import("./../../../src/pages/products/botellas_vino.js" /* webpackChunkName: "component---src-pages-products-botellas-vino-js" */),
  "component---src-pages-products-cervezas-js": () => import("./../../../src/pages/products/cervezas.js" /* webpackChunkName: "component---src-pages-products-cervezas-js" */),
  "component---src-pages-products-espirituosas-js": () => import("./../../../src/pages/products/espirituosas.js" /* webpackChunkName: "component---src-pages-products-espirituosas-js" */),
  "component---src-pages-products-espumantes-js": () => import("./../../../src/pages/products/espumantes.js" /* webpackChunkName: "component---src-pages-products-espumantes-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-kegs-cervezas-js": () => import("./../../../src/pages/products/kegs_cervezas.js" /* webpackChunkName: "component---src-pages-products-kegs-cervezas-js" */),
  "component---src-pages-products-procesos-especiales-js": () => import("./../../../src/pages/products/procesos-especiales.js" /* webpackChunkName: "component---src-pages-products-procesos-especiales-js" */),
  "component---src-pages-products-tapas-js": () => import("./../../../src/pages/products/tapas.js" /* webpackChunkName: "component---src-pages-products-tapas-js" */)
}

